import { IconButton, Paper, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Table, Grid, Button, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import MainCard from 'ui-component/cards/MainCard'
import {  IconDownload } from '@tabler/icons';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import axios from 'axios';
import FailureNotification from 'ui-component/FailureNotification';
import RefreshIcon from '@mui/icons-material/Refresh';
import SuccessNotification from 'ui-component/SuccessNotification';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const icons = { IconDownload,DeleteOutlinedIcon };



const useStyles = makeStyles((theme) => ({
    table1: {
        "& .MuiTableRow-root": {
          border: '1px solid #ececec'
        },
        "& .MuiTableCell-body":{
          padding:'5px 10px',
          border: '1px solid #ececec',
        },
        "& .MuiTableCell-head":{
            padding:theme.spacing(2),
            border: '1px solid #ececec',
            fontWeight:1000,
            background: '#4F81BD',
            color: 'white'
        }
      },
      redIcon1:{
          color:'red'
      },
      greenIcon1:{
          color:'green'
      }
}));

function ExportUserFileDownload({status, SearchInputValue, adminFind, appId, appData}) {

    const { REACT_APP_API_ENDPOINT } = process.env

   

  

    const classesNames = useStyles();
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [rowPerPage, setRowsPerPage] = React.useState(5);

    
      //*******|| ALERT MESSAGE FUNCTIONALITY || ********* */

   const [openError, setopenError] = useState(false);
   const [ErrorMessage, setErrorMessage] = useState("");
   const [openSuccess, setOpenSuccess] = useState(false);
   const [SuccessMessage, setSuccessMessage] = useState("");

    
    // function printDate(timestamp){
    //     const date =  new Date(timestamp);
    //     return date.getFullYear()+'-'+date.getMonth()+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
    // }




    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };





    const [ fileData, setFileData] = useState([])



    
 // **** SUPER ADMIN FILE USE CASE **** //

  const ExportAdminUsers = async () => {
    await axios.post(`${REACT_APP_API_ENDPOINT}/users/get-users-excel-async?searchKeyword=${SearchInputValue}`,{
      filterType: status === "ALL" ? '0': '1',
      value:status
    }).then( response => {
      
      getAsyncFileListAdmin()
      // const type = response.headers['content-type'];
      // const blob = new Blob([response.data], {
      //   type: type,
      //   encoding: 'UTF-8',
      // });
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = 'Users.xlsx';
      // link.click();
    }).catch((err) => {
      if(err.response.status === 429){
        setopenError(true);
        setErrorMessage('Too Many Requests')
    }else{
        console.log(err)
    }
    });
  }

  const getAsyncFileListAdmin = async () => {
    await axios.get(`${REACT_APP_API_ENDPOINT}/users/get-users-excel-async`)
    .then( res => {
      setFileData(res.data)
      setCount(res.data.length);
    }).catch( err => console.log(err))
  }

  const downloadFileAdmin = async (job) => {
    await   axios.get(`${REACT_APP_API_ENDPOINT}/users/download-users-excel-async/${job}`,{
        responseType: 'blob'
    })
    .then(response=>{
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'status.xlsx'
        link.click()
    }).catch( err => {
        console.log(err)
    })         
}

const handleDeleteAdmin = async (job) => {
    await axios.delete(`${REACT_APP_API_ENDPOINT}/users/delete-users-excel-async/${job}`)
    .then(res => {
        if(res.data === true){
            setOpenSuccess(true)
            setSuccessMessage("File Deleted Successfully")
            getAsyncFileListAdmin()
        }else{
            setopenError(true)
            setErrorMessage("Something Went wrong, please try again after sometime")
        }
    }).catch(err => {
       console.log(err)
    })
  }


  // *** APP ADMIN FILE USE CASE *** //

  const ExportAppAdminUsers = async () => {
    await axios.post(`${REACT_APP_API_ENDPOINT}/apps/get-application-users-async/${appId}?limit=1000&q=${SearchInputValue}`,{
      filterType: status === "ALL" ? '0': '1',
      value:status
    }).then( response => {

      getAsyncFileListAppAdmin()
      // const type = response.headers['content-type'];
      // const blob = new Blob([response.data], {
      //   type: type,
      //   encoding: 'UTF-8',
      // });
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = 'Users.xlsx';
      // link.click();
    }).catch((err) => {
      if(err.response.status === 429){
        setopenError(true);
        setErrorMessage('Too Many Requests')
    }else{
        console.log(err)
    }
    });
  }

  const getAsyncFileListAppAdmin = async () => {

    await axios.get(`${REACT_APP_API_ENDPOINT}/apps/get-application-users-async/${appId}`)
    .then( res => {
      let updatedFileData = res.data.map(item => {
         let appLabel = appData && appData.find( appData => item.applicationId === appData.id)
         return {...item, appLabel: appLabel.label}
      })
      setFileData(updatedFileData)
      let count = res.data ?  res.data.length : 0
      setCount(count);
    }).catch( err => console.log(err))
  }

  const downloadFileAppAdmin = async (job) => {
    await   axios.get(`${REACT_APP_API_ENDPOINT}/apps/download-application-excel-async/${appId}/${job}`,{
        responseType: 'blob'
    })
    .then(response=>{
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'status.xlsx'
        link.click()
    }).catch( err => {
        console.log(err)
    })         
}

const handleDeleteAppAdmin = async (job) => {
    await axios.delete(`${REACT_APP_API_ENDPOINT}/apps/delete-application-excel-async/${appId}/${job}`)
    .then(res => {
        if(res.data === true){
            setOpenSuccess(true)
            setSuccessMessage("File Deleted Successfully")
            getAsyncFileListAppAdmin()
        }else{
            setopenError(true)
            setErrorMessage("Something Went wrong, please try again after sometime")
        }
    }).catch(err => {
       console.log(err)
    })
  }


 
  

useEffect( () => {
   
    adminFind ?
  getAsyncFileListAdmin() : getAsyncFileListAppAdmin()
  //eslint-disable-next-line
}, [])




 //***************** || Alert Handle Close  || ************************* */ 

 const handleCloseAlertError = () => {
    setErrorMessage("");
    setopenError(false);
  };

  const handleCloseAlertSuccess = () => {
    setSuccessMessage("");
    setOpenSuccess(false);
  };

  return (
    <>
    <FailureNotification OpenError={openError} handleClose={handleCloseAlertError} ErrorMessage={ErrorMessage} />
    <SuccessNotification openSuccess={openSuccess} handleAlertSuccess={handleCloseAlertSuccess} SuccessMessage={SuccessMessage} />

    <MainCard title="Export Or Download Users" id="Main">
    <Grid container xs={12}>
                <Grid item xs={6} >
                              <Button variant="contained" 
                              color="primary"
                              startIcon={<CloudDownloadIcon sx={{ fontSize: 20 }} />}
                              sx={{ padding: '5px 10px' }} // Adjust the padding her
                              onClick={ adminFind ? ExportAdminUsers : ExportAppAdminUsers}
                            >Export  Users
                          
                            </Button>
                            </Grid>
                <Grid item xs={6} style={{display:'flex', alignItems:'center', justifyContent:'right'}}>
                <Tooltip title="Refresh Data">
                    <IconButton aria-label="delete" onClick={adminFind ? getAsyncFileListAdmin : getAsyncFileListAppAdmin} >
                            <RefreshIcon />
                    </IconButton>
                </Tooltip>   
                </Grid> 
    </Grid>
         <TableContainer component={Paper} style={{marginTop:"1rem"}}>
                <Table
                    sx={{ minWidth: 650, padding: 0, margin: 0 }} 
                    aria-label="simple table" 
                    className={classesNames.table1}
                >
                    <TableHead>
                        <TableRow>
                            {!adminFind && <TableCell align="center">Application Name</TableCell>}
                            <TableCell align="center">File Name</TableCell>
                            <TableCell align="center">Created On</TableCell>
                            <TableCell align='center'>File Size</TableCell>
                            <TableCell align="center">Download status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                           fileData && fileData?.length>0?
                            fileData
                            .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                            .map((row, id) => (
                                <TableRow
                                    key={id}
                                    style ={ id % 2? { background : "white" }:{ background : "#eaf0ff" }}
                                >
                               { !adminFind &&  <TableCell>{row.appLabel}</TableCell> }
                                <TableCell>{row.jobId}</TableCell>
                                <TableCell>{
                                 row.createdOnFormat  
                                }</TableCell>
                                <TableCell>{ row.size }</TableCell>
                                <TableCell>
                                    {
                                        row.status === true ? 
                                            <div>
                                            <IconButton className={classesNames.greenIcon1} onClick={adminFind ? ()=>downloadFileAdmin(row.jobId) : () => downloadFileAppAdmin(row.jobId)}>
                                                   <icons.IconDownload></icons.IconDownload>
                                            </IconButton>
                                            <IconButton className={classesNames.redIcon1} onClick={ adminFind ? ()=>handleDeleteAdmin(row.jobId) : ()=> handleDeleteAppAdmin(row.jobId)}>
                                                <icons.DeleteOutlinedIcon></icons.DeleteOutlinedIcon>
                                            </IconButton>
                                            </div>
                                            : "Please Wait For the File to Process"
                                    }
                                </TableCell>
                                </TableRow>
                            ))
                            :
                            <>
                                <TableRow>
                                    <TableCell colSpan={5} component="th" scope="row" style={{textAlign: 'center',fontWeight:'bold'}}>
                                        No Record Found
                                    </TableCell>
                                </TableRow>
                            </>
                        }
                    </TableBody>
                   
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

    </MainCard>
     
    </>
  )
}

export default ExportUserFileDownload;